import request from '@/utils/request'

// 获取列表
export function getListRequest(params) {
  return request({
    url: '/admin/zr_giftbag/getList',
    method: 'get',
    params
  })
}

// 获取列表 商品
export function getselectproductlist(params) {
  return request({
    url: '/admin/zr_giftbag/getselectproductlist',
    method: 'get',
    params
  })
}

// 获取列表 优惠券
export function getselectcouponlist(params) {
  return request({
    url: '/admin/zr_giftbag/getselectcouponlist',
    method: 'get',
    params
  })
}


// 下拉框
export function getSelectRequest(params) {
  return request({
    url: '/admin/zr_giftbag/getSelectgrade',
    method: 'get',
    params
  })
}

// 删除
export function delRequest(params) {
  return request({
    url: '/admin/zr_giftbag/del',
    method: 'get',
    params
  })
}

// 获取详情信息
export function getdatilRequest(params) {
  return request({
    url: '/admin/zr_giftbag/getdatil',
    method: 'get',
    params
  })
}

// 修改信息
export function editRequest(data) {
  return request({
    url: '/admin/zr_giftbag/edit',
    method: 'post',
    data
  })
}

// 添加信息
export function addRequest(data) {
  return request({
    url: '/admin/zr_giftbag/add',
    method: 'post',
    data
  })
}